import React, { Component } from 'react';
import Intro from './intro/intro';

export default class About extends Component {
  render() {
    return (
      <>
        <Intro />
       {/* 
        <Education />
        */}
      </>
    );
  }
}
